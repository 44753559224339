<template>
  <div>
    <loading-bar :value="loadingOptions"/>
    <v-container class="pa-0" fluid v-if="tournament">
      <v-row dense>
        <v-col cols="12" class="text-end">
          <add-on-drawer :tournament="tournament" v-if="tournament.id"></add-on-drawer>
        </v-col>
        <v-col cols="12" class="py-0">
          <!-- ORGANIZATION -->
          <v-card v-if="organizations.length > 1 && !divisionId" flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3 grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Host
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-autocomplete
                    :items="organizations"
                    v-model="tournament.organization"
                    label="Choose a host"
                    item-text="name"
                    item-value="id"
                    return-object
                    :disabled="disabled || organizations.length === 1"
                    color="color3"
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <!-- LEAGUE SETTINGS -->
          <v-card v-if="tournament && tournament.isLeague && !divisionId" flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3 grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                League Settings
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <league-settings :disabled="disabled" :tournament="tournament"></league-settings>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <!-- DETAILS -->
          <v-card flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3  grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Details
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid class="pt-0">
              <v-row dense justify="end">
                <v-btn
                  color="color3"
                  x-small
                  text
                  @click.stop="advanced = !advanced"
                  :disabled="disabled || forceAdvanced"
                >go {{advanced ? 'simple' : 'advanced'}}</v-btn>
              </v-row>
              <v-expand-transition>
                <v-row dense v-if="advanced || forceAdvanced">
                  <v-col cols="12" sm="6">
                    <v-select
                      :items="sports"
                      v-model="sport"
                      item-text="text"
                      item-value="id"
                      label="Sport"
                      color="color3"
                      item-color="color3"
                      :disabled="disabled"
                      attach
                    >
                      <template v-slot:append-outer>
                        <v-btn
                          v-if="!divisionId && !simpleSport"
                          color="warning"
                          fab x-small
                          @click.stop="onWarningClick('Sport')"
                        >
                          <v-icon>fas fa-exclamation</v-icon>
                        </v-btn>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-slide-x-transition>
                      <v-select
                        v-if="showSurface"
                        :items="surfaces"
                        v-model="surface"
                        item-text="text"
                        item-value="id"
                        label="Surface"
                        color="color3"
                        item-color="color3"
                        :disabled="disabled"
                        attach
                      >
                        <template v-slot:append-outer>
                          <v-btn
                            v-if="!divisionId && !simpleSurface"
                            color="warning"
                            fab x-small
                            @click.stop="onWarningClick('Surface')"
                          >
                            <v-icon>fas fa-exclamation</v-icon>
                          </v-btn>
                        </template>
                      </v-select>
                    </v-slide-x-transition>
                  </v-col>
                </v-row>
              </v-expand-transition>
              <!-- NAME, 1 day checkbox & Sanctioning -->
              <template  v-if="!divisionId">
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      :items="statuses"
                      v-model="tournament.statusId"
                      :label="`${tournament.eventType} Status`"
                      :disabled="disabled || (tournament.statusId === 100 && !user.vbl)"
                      color="color3"
                      item-color="color3"
                      attach
                    ></v-select>
                  </v-col>
                  <!-- Name -->
                  <v-col cols="12" md="6">
                    <v-text-field
                      :label="`${tournament.eventType} Name*`"
                      v-model="tournament.name"
                      :disabled="disabled"
                      color="color3"
                    ></v-text-field>
                  </v-col>
                  <!-- Sanctioning -->
                  <v-col cols="12" sm="6" v-if="tournament.isCompetitive || user.vblXXX">
                    <sanctioning-bodies :tournament="tournament" :disabled="disabled"></sanctioning-bodies>
                  </v-col>
                </v-row>
                <!-- Avp Sanction -->
                <v-expand-transition>
                  <div v-if="tournament.useAvpSanctionOptions">
                    <v-row dense>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="avp.juniors"
                          v-model="tournament.avpJrSanction"
                          label="USAVP Event Type - Junior Divisions"
                          :disabled="disabled || !(user && user.isAvpAdmin)"
                          color="color3"
                          item-color="color3"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="avp.adults"
                          v-model="tournament.avpAdultSanction"
                          label="USAVP Event Type - Adult Divisions"
                          :disabled="disabled || !(user && user.isAvpAdmin)"
                          color="color3"
                          item-color="color3"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="tournament.isAVP">
                        <v-select
                          :items="avp.memberships"
                          v-model="tournament.avpMembership"
                          label="AVP Membership Type"
                          :disabled="disabled"
                          color="color3"
                          item-color="color3"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
                <!-- Members Only -->
                <v-expand-transition>
                  <div v-if="tournament.isCompetitive && tournament.organization && tournament.organization.username !== 'p1440'">
                    <v-row dense>
                      <v-col cols="12" sm="6" md="4">
                        <membership
                          :disabled="disabled || (tournament.isBVNE && ![177, 179].includes(tournament.organization.id))"
                          :tournament="tournament"
                        ></membership>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
                <!-- Point System -->
                <v-expand-transition>
                  <div v-if="pointOptions && pointOptions.length && tournament.isCompetitive">
                    <v-row dense>
                      <v-col cols="12">
                        <v-select
                          :items="pointOptions === 'loading' ? [] : pointOptions"
                          item-text="name"
                          return-object
                          v-model="tournament.pointSystems"
                          label="Point Systems"
                          :loading="pointOptions === 'loading'"
                          attach chips deletable-chips multiple
                          :disabled="disabled || tournament.id > 0"
                          color="color3"
                          item-color="color3"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </template>
              <!-- Division Shit -->
              <template v-else>
                <v-expand-transition>
                  <div v-if="advanced || forceAdvanced">
                    <v-row dense>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          label="Division Name"
                          v-model="division._Name"
                          :disabled="disabled"
                          hide-details
                          color="color3"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          color="color3"
                          v-model="division.numOfPlayers"
                          label="Number Of Players"
                          type="number"
                          hint="Not Doubles? Enter the number of player on a team"
                          persistent-hint
                          :disabled="disabled"
                          @blur="playerBlur"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          color="color3"
                          v-model="division.numAllowedOnRoster"
                          label="Number Allowed On Roster"
                          type="number"
                          hint="How many players are allowed on the roster?"
                          persistent-hint
                          :disabled="disabled"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="d-flex align-center">
                        <v-switch
                          v-if="ncaaAllowed"
                          label="Use Captains"
                          v-model="division.props"
                          value="useCaptains"
                          color="success"
                          :disabled="disabled"
                          key="captians"
                          class="shrink mr-3 mt-0"
                          hide-details
                        ></v-switch>
                        <v-slide-x-transition>
                          <v-switch
                            v-if="ncaaAllowed"
                            label="Collegiate Event"
                            v-model="division.props"
                            value="ncaa"
                            color="success"
                            :disabled="disabled"
                            key="ncaa"
                            class="shrink mr-3 mt-0"
                            hide-details
                          ></v-switch>
                        </v-slide-x-transition>
                        <v-slide-x-transition>
                          <v-switch
                            v-if="division.numOfPlayers <= 2"
                            label="Use Team Names"
                            v-model="division.props"
                            value="useTeamNames"
                            color="success"
                            :disabled="disabled"
                            key="teamnames"
                            class="shrink mr-3 mt-0"
                            hide-details
                          ></v-switch>
                        </v-slide-x-transition>
                        <v-slide-x-transition>
                          <v-switch
                            key="duals"
                            v-if="division.numOfPlayers > 3"
                            class="shrink mr-3 mt-0"
                            label="Duals"
                            v-model="division.props"
                            value="lineups"
                            color="success"
                            :disabled="disabled"
                            hide-details
                          ></v-switch>
                        </v-slide-x-transition>
                        <v-slide-x-transition>
                          <v-switch
                            v-if="division.lineups || division.useTeamNames || division.isNcaa"
                            class="shrink mr-3 mt-0"
                            label="Allow Empty Roster Registration"
                            v-model="division.props"
                            value="allowZeroPlayers"
                            color="success"
                            :disabled="disabled"
                            hide-details
                          ></v-switch>
                        </v-slide-x-transition>
                      </v-col>
                      <v-alert
                        :value="division.isDuals"
                        type="info"
                        prominent text
                        border="left"
                        transition="scale-transition"
                        :color="disabled ? 'grey' : null"
                        width="100%"
                      >
                        The dual count will be the number of players per team divided by 2. Please adjust accordingly.
                        <br><br>
                        <b>You current dual count will be {{division.numOfPlayers/2}}</b>
                      </v-alert>
                    </v-row>
                  </div>
                </v-expand-transition>
                <v-row dense>
                  <v-col cols="6" sm="4" lg="3">
                    <v-autocomplete
                      v-model="division.ageType"
                      :items="options.ageTypeOptions"
                      item-text="name"
                      item-value="id"
                      label="Age Group*"
                      return-object
                      :disabled="disabled"
                      color="color3"
                      item-color="color3"
                      attach
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <v-autocomplete
                      v-model="division.gender"
                      :items="genders"
                      item-text="name"
                      item-value="id"
                      label="Gender*"
                      return-object
                      :disabled="disabled"
                      color="color3"
                      item-color="color3"
                      attach
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <v-autocomplete
                      v-model="division.division"
                      :items="divisions"
                      item-text="name"
                      item-value="id"
                      label="Division*"
                      return-object
                      :menu-props="{'closeOnClick':true, 'closeOnContentClick': true}"
                      :disabled="disabled"
                      color="color3"
                      item-color="color3"
                      attach
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" sm="4" lg="3">
                    <v-text-field
                      label="Max Teams"
                      v-model="division.maxTeams"
                      type="number"
                      :disabled="disabled"
                      color="color3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <div v-if="division.maxTeams">
                    <v-row dense>
                      <v-col cols="6" sm="4" lg="3">
                        <v-select
                          label="Waitlist Default Sort"
                          v-model="division.waitlistSort"
                          :disabled="disabled"
                          item-text="text"
                          item-value="value"
                          color="color3"
                          item-color="color3"
                          hide-details
                          class="mt-0"
                          :items="[
                            { text: 'AAU Points', value: 'aauPoints' },
                            { text: 'AVPA Points', value: 'avpaPoints' },
                            { text: 'BVNE Points', value: 'bvnePoints' },
                            { text: 'p1440 Points', value: 'p1440Points' },
                            { text: 'Registration Date', value: 'unix' },
                            { text: 'Alphabetical', value: 'name' }
                          ]"
                        ></v-select>
                      </v-col>
                      <v-col cols="6" sm="4" lg="3">
                        <v-switch
                            label="Hide Waitlist"
                            v-model="division.props"
                            value="hideWaitlist"
                            color="success"
                            :disabled="disabled"
                            key="hideWaitlist"
                            class="shrink mr-3 mt-0"
                            hide-details
                          ></v-switch>
                      </v-col>
                    </v-row>
                  </div>
                </v-expand-transition>
              </template>
              <!-- DATE & TIMES -->
              <v-row v-if="tournament.isTrainingSession && !divisionId" dense>
                <v-col cols="12">
                  <reoccuring-settings
                    :startDate.sync="startDate"
                    :startTime.sync="playTime"
                    :disabled="disabled"
                    :endDate.sync="tournament.endDate"
                    :settings.sync="tournament.reoccuringSettings"
                  ></reoccuring-settings>
                </v-col>
              </v-row>
              <v-row dense v-else>
                <!-- START & END DATE -->
                <v-col cols="12" sm="6">
                  <v-row class="pl-3">
                    <date-time-picker
                      :datetime.sync="startDate"
                      label="Start"
                      key="start"
                      :disabled="disabled"
                      type="date"
                      :warning="{date: !divisionId && !simpleStart}"
                      @warning-click="onWarningClick"
                    ></date-time-picker>
                    <!-- One Day -->
                    <v-checkbox
                      color="success"
                      label="One day"
                      v-model="oneDay"
                      hide-details
                      :disabled="disabled"
                      readonly
                      @click.stop="toggleOneday"
                      class="shrink mx-3"
                      v-if="!divisionId && !(tournament.isLeague && !user.vbl)"
                    ></v-checkbox>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <date-time-picker
                    v-if="!oneDay && !divisionId && !(tournament.isLeague && !user.vbl)"
                    :datetime.sync="tournament.endDate"
                    key="end"
                    label="End"
                    :disabled="disabled"
                    type="date"
                  ></date-time-picker>
                </v-col>
                <!-- CHECKIN TIME -->
                <v-col cols="12" sm="6">
                  <date-time-picker
                    :datetime.sync="checkIn"
                    label="Check-in"
                    :disabled="disabled"
                    type="time"
                    key="checkin"
                    :inputIsTime="true"
                    :warning="{time: !divisionId && !simpleCheckIn}"
                    @warning-click="onWarningClick"
                  ></date-time-picker>
                </v-col>
                <!-- PLAY TIME -->
                <v-col cols="12" sm="6">
                  <date-time-picker
                    :datetime.sync="playTime"
                    :label="tournament.isTournament ? 'Play Start' : 'Start'"
                    :disabled="disabled"
                    key="play"
                    type="time"
                    :inputIsTime="true"
                    :warning="{time: !divisionId && !simplePlay}"
                    @warning-click="onWarningClick"
                  ></date-time-picker>
                </v-col>
              </v-row>
              <!-- LOCATION -->
              <v-row dense>
                <v-autocomplete
                  ref="locationSelect"
                  :items="locations"
                  item-text="name"
                  item-value="id"
                  label="Location"
                  prepend-icon="fas fa-map-marker-alt"
                  return-object
                  v-model="location"
                  :disabled="disabled"
                  attach
                  color="color3"
                  item-color="color3"
                >
                  <v-divider slot="append-item" class="mb-2"></v-divider>
                  <v-list-item slot="append-item" @click.stop="onAddLocationClick">
                    <v-list-item-content>
                      <v-list-item-title>Add New Location</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <template v-slot:append-outer>
                    <v-btn
                      v-if="!divisionId && !simpleLocation"
                      color="warning"
                      fab x-small
                      @click.stop="onWarningClick('Location')"
                    >
                      <v-icon>fas fa-exclamation</v-icon>
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-row>
            </v-container>
          </v-card>
          <!-- COVERPHOTO -->
          <v-card v-if="!divisionId" flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3  grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Cover Photo
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12">
                  <v-img
                    v-if="tournament.coverPhotoUrl"
                    :src="tournament.coverPhotoUrl"
                    :cover="!tournament.containCover"
                    :contain="tournament.containCover"
                    :height="$vuetify.breakpoint.xs ? 250 : 360"
                  >
                    <div :class="{'block': disabled}"></div>
                    <v-row class="fill-height" align="end" v-if="false">
                      <div class="pa-4" style="width: 100%;">
                        <v-col class="pb-0 text-right" cols="12" >
                          <v-btn-toggle
                            v-model="position"
                          >
                            <v-btn color="color3Text color3--text" :value="'top left'" v-if="false">
                              <v-icon data-fa-transform="rotate-45">fas fa-arrow-left</v-icon>
                            </v-btn>
                            <v-btn color="color3Text color3--text" :value="'top center'">
                              <v-icon>fas fa-arrow-up</v-icon>
                            </v-btn>
                            <v-btn color="color3Text color3--text" :value="'top right'">
                              <v-icon>fas fa-arrow-right</v-icon>
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col class="py-0 text-right" cols="12" >
                          <v-btn-toggle
                            v-model="position"
                          >
                            <v-btn color="color3Text color3--text" :value="'center left'">
                              <v-icon>fas fa-arrow-left</v-icon>
                            </v-btn>
                            <v-btn color="color3Text color3--text" :value="'center center'">
                              <v-icon>fas fa-expand-arrows-alt</v-icon>
                            </v-btn>
                            <v-btn color="color3Text color3--text" :value="'center right'">
                              <v-icon>fas fa-arrow-right</v-icon>
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col class="pt-0 text-right" cols="12" >
                          <v-btn-toggle
                            v-model="position"
                          >
                            <v-btn color="color3Text color3--text" :value="'bottom left'" v-if="false">
                              <v-icon>fas fa-arrow-left</v-icon>
                            </v-btn>
                            <v-btn color="color3Text color3--text" :value="'bottom center'">
                              <v-icon>fas fa-arrow-down</v-icon>
                            </v-btn>
                            <v-btn color="color3Text color3--text" :value="'bottom right'">
                              <v-icon>fas fa-arrow-right</v-icon>
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </div>

                    </v-row>
                  </v-img>
                </v-col>
                <v-col class="text-right" cols="12" >
                  <v-checkbox color="success" label="Contain" v-model="tournament.containCover" class="shrink" hide-details :disabled="disabled"></v-checkbox>
                  <v-checkbox color="success" label="Flyer Mode" v-model="isFlyer" class="shrink" hide-details :disabled="disabled"></v-checkbox>
                </v-col>
                <v-col cols="12" v-if="!disabled">
                  <label class="v-label v-label--active theme--light">Choose a photo</label>
                  <image-library
                    v-if="tournament.organization"
                    :username="tournament.organization.username"
                    :oId="tournament.organization.id"
                    :sb="tournament.sanctionedBy"
                    single-row
                    :cover="!tournament.containCover"
                    no-preview
                    :img-height="36 * 4"
                    :img-width="64 * 4"
                    @preview-click="onPreviewClick"
                  ></image-library>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <!-- ADDITIONAL INFO -->
          <v-card v-if="!divisionId" flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3  grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Additional Info
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-card flat>
                <div :class="{'block': disabled}"></div>
                <v-container class="pa-0" fluid>
                  <v-row dense>
                    <v-col cols="12">
                      <html-editor v-model="tournament.description" :disabled="disabled" ></html-editor>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-card-text>
          </v-card>
          <!-- DIVISIONS -->
          <v-card v-if="!divisionId && !tournament.isTrainingSession" flat tile>
            <v-toolbar dense flat :color="disabled ? 'grey lighten-3  grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Divisions
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <division-list-simple
                :tournament="tournament"
                :busy="saving"
                :viewOnly="viewOnly"
                :options="options"
              ></division-list-simple>
            </v-card-text>
          </v-card>
          <!-- REGISTRATION WINDOWS -->
          <v-card flat tile>
            <v-toolbar dense flat :color="(disabled || !!tournament.externalRegistrationUrl) ? 'grey lighten-3  grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Registration
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid class="pt-0">
              <v-row dense justify="end" style="min-height:36px">
                <v-btn
                  color="color3"
                  text
                  x-small
                  v-if="!disabled && !tournament.externalRegistrationUrl && editableDivision.registrationWindows.length < 2"
                  @click.stop="advancedW = !advancedW"
                >{{advancedW ? 'simple' : 'advanced'}}</v-btn>
              </v-row>
              <v-alert type="warning" v-if="tournament.externalRegistrationUrl" :value="true">
                These features are not available with an external registration URL.
              </v-alert>
              <v-scale-transition group tag="div">
                <registration-window
                  v-for="(item,i) in editableDivision.registrationWindows"
                  :key="`${item.id}_${i}`"
                  :window="item"
                  :beforeDate="startDate"
                  :simplicity="registrationSimplicity.windows[i]"
                  :disabled="disabled || !!tournament.externalRegistrationUrl"
                  @warning-click="onWarningClick"
                  @change="updateRegistrationWindow($event, i)"
                  :multi="editableDivision.windows.length > 1"
                  :advanced="advancedW"
                  @delete-click="deleteWindow(i)"
                  :hidePer="!(tournament.isTournament || tournament.isLeague)"
                  :tournament="tournament"
                  :division="editableDivision"
                  :orgProps="orgProps"
                ></registration-window>
              </v-scale-transition>
              <v-expand-transition>
                <div v-if="advancedW || editableDivision.registrationWindows.length > 1">
                  <v-row dense justify="end">
                    <v-btn
                      color="color3"
                      text
                      x-small
                      v-if="!disabled && !tournament.externalRegistrationUrl"
                      @click.stop="addWindow"
                    >Add a window</v-btn>
                  </v-row>
                </div>
              </v-expand-transition>
              <!-- DT REFUND -->
              <date-time-picker
                :datetime.sync="dtRefundCutoff"
                label="Refund Cutoff"
                key="refund"
                :disabled="disabled || !!tournament.externalRegistrationUrl"
                :warning="{date: !simpleRefund.date, time: !simpleRefund.time}"
                @warning-click="onWarningClick"
              ></date-time-picker>
              <!-- REFUND POLICY -->
              <v-row dense>
                <v-col cols="12" sm="6">
                  <refund-policy-selector
                    :username="tournament.organization.username"
                    v-model="refundPolicy"
                    :disabled="disabled || !!tournament.externalRegistrationUrl"
                    :useDefault="tournament.id === 0"
                    :warning="!simpleRefundPolicy"
                    @warning-click="onWarningClick"
                  ></refund-policy-selector>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    color="success"
                    label="Allow TBD"
                    v-if="(tournament.isTournament || tournament.isLeague) && !(division && division.allowZeroPlayers)"
                    v-model="allowTbd"
                    :value="true"
                    :disabled="disabled || !!tournament.externalRegistrationUrl"
                    hint="Check this to allow registrants to enter TBD for some or all players"
                    persistent-hint
                  ></v-checkbox>

                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <!-- Cart Fees -->
          <v-card flat tile v-if="showCartFees">
            <v-toolbar dense flat :color="(disabled || !!tournament.externalRegistrationUrl) ? 'grey lighten-3  grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Cart Fees
              </v-toolbar-title>
            </v-toolbar>
            <cart-fees
              :disabled="disabled"
              :tournament="tournament"
              :divisionId="editableDivision.id"
            ></cart-fees>
          </v-card>
          <!-- REGISTRATION FIELDS -->
          <v-card flat tile>
            <v-toolbar dense flat :color="(disabled || !!tournament.externalRegistrationUrl) ? 'grey lighten-3  grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Registration Fields
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <v-alert type="warning" v-if="tournament.externalRegistrationUrl" :value="true">
                These features are not available with an external registration URL.
              </v-alert>
              <v-row dense>
                <v-col cols="12">
                  <h2>What fields would you like to collect during registration?</h2>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6" sm="3" md="2">
                  First Name
                  <v-radio-group v-model="forcedRadio" column>
                    <v-radio label="Ask" value="fields" hide-details disabled color="success"></v-radio>
                    <v-radio label="Require" value="requiredFields" hide-details disabled color="success"></v-radio>
                    <v-radio label="Skip" value="" hide-details disabled color="success"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6" sm="3" md="2">
                  Last Name
                  <v-radio-group v-model="forcedRadio" column>
                    <v-radio label="Ask" value="fields" hide-details disabled color="success"></v-radio>
                    <v-radio label="Require" value="requiredFields" hide-details disabled color="success"></v-radio>
                    <v-radio label="Skip" value="" hide-details disabled color="success"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6" sm="3" md="2" v-for="(field, i) in fieldChoices" :key="i">
                  {{field.label}}
                    <v-btn
                      v-if="!field.simple"
                      color="warning"
                      fab x-small
                      @click.stop="onWarningClick(field)"
                    >
                      <v-icon>fas fa-exclamation</v-icon>
                    </v-btn>
                  <v-radio-group v-model="field.list" column @change="onFieldChange($event, field.value)">
                    <v-radio v-if="!field.skipAsk" label="Ask" value="fields" hide-details :disabled="disabled || !!tournament.externalRegistrationUrl" color="success"></v-radio>
                    <v-radio label="Require" value="requiredFields" hide-details :disabled="disabled || !!tournament.externalRegistrationUrl" color="success"></v-radio>
                    <v-radio label="Skip" :value="false" hide-details :disabled="disabled || !!tournament.externalRegistrationUrl" color="success"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col class="text-right" cols="12" >
                  <v-btn
                    color="color3"
                    text
                    class="xsmall ma-0 pa-0"
                    @click.stop="showShowcase = !showShowcase"
                    v-if="!forceShowcase"
                  >{{showShowcase ? 'hide ': ''}}Additional fields</v-btn>
                </v-col>
              </v-row>
              <v-row dense v-if="showcase">
                <v-col cols="6" sm="3" md="2" v-for="(field, i) in additionalFields" :key="i">
                  {{field.label}}
                    <v-btn
                      v-if="!field.simple"
                      color="warning"
                      fab x-small
                      @click.stop="onWarningClick(field)"
                    >
                      <v-icon>fas fa-exclamation</v-icon>
                    </v-btn>
                  <v-radio-group v-model="field.list" column @change="onFieldChange($event, field.value)">
                    <v-radio v-if="!field.skipAsk" label="Ask" value="fields" hide-details :disabled="disabled || !!tournament.externalRegistrationUrl" color="success"></v-radio>
                    <v-radio label="Require" value="requiredFields" hide-details :disabled="disabled || !!tournament.externalRegistrationUrl" color="success"></v-radio>
                    <v-radio label="Skip" :value="false" hide-details :disabled="disabled || !!tournament.externalRegistrationUrl" color="success"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row dense v-if="isGnbv">
                <v-col cols="6" sm="3" md="2" v-for="(field, i) in bvneFields" :key="i">
                  {{field.label}}
                    <v-btn
                      v-if="!field.simple"
                      color="warning"
                      fab x-small
                      @click.stop="onWarningClick(field)"
                    >
                      <v-icon>fas fa-exclamation</v-icon>
                    </v-btn>
                  <v-radio-group v-model="field.list" column @change="onFieldChange($event, field.value)">
                    <v-radio v-if="!field.skipAsk" label="Ask" value="fields" hide-details :disabled="disabled || !!tournament.externalRegistrationUrl" color="success"></v-radio>
                    <v-radio label="Require" value="requiredFields" hide-details :disabled="disabled || !!tournament.externalRegistrationUrl" color="success"></v-radio>
                    <v-radio label="Skip" :value="false" hide-details :disabled="disabled || !!tournament.externalRegistrationUrl" color="success"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <!-- REGISTRATION FLAGS -->
          <v-card v-if="flags.length" flat tile>
            <v-toolbar dense flat :color="(disabled || !!tournament.externalRegistrationUrl) ? 'grey lighten-3  grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Registration Validations
              </v-toolbar-title>
            </v-toolbar>
            <v-container fluid>
              <v-alert type="warning" v-if="tournament.externalRegistrationUrl" :value="true">
                These features are not available with an external registration URL.
              </v-alert>
              <v-row dense>
                <v-checkbox
                  :disabled="disabled || !!tournament.externalRegistrationUrl"
                  v-for="(flag, i) in flags"
                  :key="flag.value"
                  :label="flag.label"
                  value="requiredFields"
                  v-model="flag.list"
                  color="success"
                  :class="i ? 'ml-2' : ''"
                  @change="onFieldChange($event, flag.value)"
                >
                  <v-btn
                    v-if="!flag.simple"
                    slot="append-outer"
                    color="warning"
                    fab x-small
                    @click.stop="onWarningClick(flag)"
                  >
                    <v-icon>fas fa-exclamation</v-icon>
                  </v-btn>
                </v-checkbox>
              </v-row>
            </v-container>
          </v-card>
          <!-- EMAIL NOTE -->
          <v-card flat tile>
            <v-toolbar dense flat :color="(disabled || !!tournament.externalRegistrationUrl) ? 'grey lighten-3  grey--text' : 'color1 color1Text--text'">
              <v-toolbar-title>
                Email Note
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert type="warning" v-if="tournament.externalRegistrationUrl" :value="true">
                This feature is not available with an external registration URL.
              </v-alert>
              <v-textarea
                name="emailNote"
                label="A quick message from your TD"
                hint="Enter any information you would like added to the tournament registration email"
                persistent-hint
                :disabled="disabled || !!tournament.externalRegistrationUrl"
                v-model="emailNote"
                color="color3"
              >
                <v-btn
                  v-if="!divisionId && !simpleEmail"
                  slot="append-outer"
                  color="warning"
                  fab x-small
                  @click.stop="onWarningClick('Email Note')"
                >
                  <v-icon>fas fa-exclamation</v-icon>
                </v-btn>
              </v-textarea>
            </v-card-text>
            <v-card-text class="py-0">
              <v-container class="pa-0" fluid>
                <v-row dense>
                  <v-col cols="12" class="text-right">
                    <v-btn color="color3" text x-small @click.stop="propEdit = !propEdit">{{propEdit ? 'Hide' : 'Show'}} Props</v-btn>
                  </v-col>
                  <v-expand-transition>
                    <v-col cols="12" v-if="propEdit">
                      <v-text-field
                        label="Props"
                        v-model="myProps"
                        :disabled="disabled"
                      ></v-text-field>
                    </v-col>
                  </v-expand-transition>
                  <v-expand-transition>
                    <v-col cols="12" v-if="showPropPush">
                      <v-btn color="color3" text x-small @click.stop="pushProps">Push props to all divisions</v-btn>
                    </v-col>
                  </v-expand-transition>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- BUTTONS -->
      <template v-if="!tournamentIn || !locked">
        <v-tooltip top>
          <span>Save</span>
          <template v-slot:activator="{ on }">
            <v-fab-transition>
              <v-btn
                v-on="on"
                color="color3 color3Text--text"
                key="save"
                v-if="!viewOnly && dirty"
                fab
                bottom
                right
                fixed
                :style="`margin-right: ${(btnOffset || 0 )+ 60}px`"
                @click.stop="onSaveClick"
                :loading="saving"
              >
                <v-icon>fas fa-save</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
        </v-tooltip>
        <v-tooltip top :key="activeFab.icon">
          <span>{{activeFab.tooltip}}</span>
          <template v-slot:activator="{ on }">
            <v-fab-transition>
              <v-btn
                v-if="optionsLoaded"
                v-on="on"
                :color="activeFab.color"
                :key="activeFab.icon"
                fab
                bottom
                right
                fixed
                :style="`margin-right: ${(btnOffset || 0)}px`"
                @click.stop="activeFab.onClick"
              >
                <v-icon>{{activeFab.icon}}</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
        </v-tooltip>

        <location-wizard ref="newLocation" @location-created="onLocationCreated" v-if="!viewOnly"></location-wizard>
        <external-registration :tournament="tournament" v-if="!viewOnly"></external-registration>
        <!-- Date adjust dialog -->
        <v-dialog
          v-model="adjDialog"
          scrollable
          persistent
          max-width="300px"
          transition="dialog-transition"
        >
          <v-card>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" class="title text-center">
                  Should I adjust the registration and refund dates?
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-btn
                  color="success white--text"
                  @click.stop="onDateChange(); adjDialog = false"
                >yes</v-btn>
                <v-btn
                  class="ml-2"
                  color="error white--text"
                  @click.stop="adjDialog = false"
                >no</v-btn>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Edit warning/agreement dialog -->
        <v-dialog
          v-model="warnMeDialog"
          scrollable
          persistent
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar color="warning">
              <v-icon class="mr-5">fas fa-exclamation</v-icon>
              <v-toolbar-title>Warning</v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-row dense class="subheading">
                <v-col cols="12">
                  You are currently editing the entire tournament and
                  there are fields that are not the same for every divisions.
                </v-col>
                <v-col cols="12">
                  These fields are marked with a <v-btn color="warning" fab x-small>
                    <v-icon>fas fa-exclamation</v-icon>
                  </v-btn>
                  next to the field.
                </v-col>
                <v-col cols="12">
                  <b>Any changes to those fields here, will overwrite that field on every division.</b>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    color="success"
                    label="I understand editing will overwrite every division"
                    v-model="editAgree"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-btn
                  color="success"
                  :disabled="!editAgree"
                  @click.stop="warnMeDialog = false"
                >OK</v-btn>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- Field warning dialog -->
        <v-dialog
          v-model="warningDialog"
          scrollable
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar color="warning">
              <v-icon class="mr-4">fas fa-exclamation</v-icon>
              <v-toolbar-title>Warning</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                text
                icon
                @click.stop="warningDialog = false"

              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="12" class="subheading">
                  {{dialog1}}.
                </v-col>
                <v-col cols="12" class="subheading">
                  <b>If you change it here, you will be overwriting ALL divisions, setting the {{dialog2}} for every division to the value you enter.</b>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- VALIDATION DIALOG-->
        <v-dialog
          v-model="validationDialog"
          max-width="500px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar color="error white--text" dense>
              <v-toolbar-title>Oops</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="white" icon text @click.stop="validationDialog = false">
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="title pt-3">
              You must {{errors | formatArray}} before you can save this tournament.
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </v-container>

    <v-dialog
      v-model="windowWarn"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="warning">
          <v-icon>fas fa-exclamation</v-icon>
          <v-toolbar-title>Warning</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="title">
          We have not had the time to add window validation and overlap prevention.
          <div class="subheading pt-2">
            It is up to you to make sure you set your window start and end date correctly.
            If you end up with overlapping windows, the first of the overlapping windows will be used during the overlap.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="color3" text @click.stop="windowWarn = false; addWindow2()">got it</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Tournament from '@/classes/Tournament'
import Division from '@/classes/TournamentDivision'
import FieldChoice from '@/classes/FieldChoice'
import Day from '@/classes/TournamentDay'
import { mapGetters } from 'vuex'
import RegistrationWindow from './RegistrationWindow.vue'
import SanctioningBodies from './SanctioningBodies.vue'
import DivisionListSimple from './DivisionListSimple.vue'
import DateTimePicker from '../../Utils/DateTimePicker.vue'
import LocationWizard from '../../Utils/LocationWizard.vue'
import ImageLibrary from '../../Organization/OrganizationImageLibrary.vue'
import ExternalRegistration from './ExternalRegistration.vue'
import moment from 'moment'
import HtmlEditor from '@/components/Utils/HtmlEditor.vue'
import firstBy from 'thenby'
import AVP from '@/classes/AvpSanctioning'
import { StatusSelect } from '@/classes/TournamentStatus'
import { Sports } from '@/classes/Sports'
import { Surfaces } from '@/classes/Surfaces'
const LeagueSettings = () => import('./LeagueSettings.vue')
const RefundPolicySelector = () => import('@/components/Cart/Manager/RefundPolicy/Selector.vue')
const CartFees = () => import('@/components/Cart/CartFees/EditorDisplay.vue')
const Membership = () => import('@/components/Tournament/Edit/Membership.vue')
const AddOnDrawer = () => import('@/components/Store/AddOnDrawer')
const ReoccuringSettings = () => import('./ReoccuringSettings.vue')

export default {
  props: ['tournamentIn', 'inDialog', 'btnOffset', 'divisionId', 'startDateIn', 'nameIn', 'eventType'],
  data () {
    return {
      pointOptions: null,
      position: null,
      oneDay: true,
      saving: false,
      tournament: null,
      forcedRadio: 'requiredFields',
      myfieldChoices: [
        new FieldChoice('Phone', 'phone'),
        new FieldChoice('Email', 'email'),
        new FieldChoice('Full Address', 'fullAddress'),
        new FieldChoice('City & State', 'cityState'),
        new FieldChoice('Country', 'country'),
        new FieldChoice('Date of birth', 'dob'),
        new FieldChoice('Grad Year', 'gradyear'),
        new FieldChoice('High School', 'highSchool'),
        new FieldChoice('Club', 'club'),
        new FieldChoice('Instagram Handle', 'instagram'),
        new FieldChoice('T-Shirt Size', 'shirtSize'),
        new FieldChoice('Height', 'height'),
        new FieldChoice('AAU', 'aau'),
        new FieldChoice('AVP', 'avp'),
        new FieldChoice('p1440', 'p1440'),
        new FieldChoice('USAV', 'usav')
      ],
      myFlags: [
        new FieldChoice('Verify Age Division - Grad Year', 'dob-gy', 'dob'),
        new FieldChoice('Verify Age Division - USAV', 'usavDob', 'dob'),
        new FieldChoice('Verify Age Division - AAU', 'aauDob', 'dob'),
        new FieldChoice('Verify Age Division - Standard', 'dobv', 'dob'),
        new FieldChoice('Verify Age Division - ISF', 'isf', 'dob'),
        new FieldChoice('Verify Age Division - Day Of', 'dayOf', 'dob'),
        new FieldChoice('School must be IBVL', 'ibvl', 'highSchool'),
        new FieldChoice('School must be same for all players', 'sameSchool', 'highSchool'),
        new FieldChoice('Override Sanction Requirement', 'noSanctionRequirement'),
        new FieldChoice('Two Factor Mobile', '2FPhone')// ,
        // new FieldChoice('Unique Email', 'emailUnique')
      ],
      myAdditionalFields: [
        new FieldChoice('Parent Info', 'parent', null, true),
        new FieldChoice('Emergency Contact', 'emergency', null, true),
        new FieldChoice('Club Director/Coach\'s Name', 'clubDirectorName'),
        new FieldChoice('Club Director/Coach\'s Email', 'clubDirectorEmail'),
        new FieldChoice('Club Director/Coach\'s Phone', 'clubDirectorPhone'),
        new FieldChoice('Committed?', 'committed'),
        new FieldChoice('Preferred Side', 'side'),
        new FieldChoice('Gender', 'gender'),
        new FieldChoice('Email Confirm', 'emailConfirm')
      ],
      myBvneFields: [
        new FieldChoice('Years Beach Experience', 'yearsExp'),
        new FieldChoice('Dominant Hand', 'hand'),
        new FieldChoice('GPA', 'gpa'),
        new FieldChoice('Player Email', 'playerEmail'),
        new FieldChoice('Player Phone', 'playerPhone')
      ],
      oDto: '',
      viewOnly: this.inDialog === undefined || this.inDialog === false,
      fab: false,
      customFields: [],
      customRequiredFields: [],
      editorOption: {
        debug: 'error',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [false, 'center', 'right'] }],
            ['link', 'clean']
          ]
        }
      },
      options: {
        ageTypeOptions: [],
        divisionOptions: [],
        genderOptions: [],
        locationOptions: []
      },
      selectedImage: null,
      adjDialog: false,
      warnMeDialog: false,
      warningDialog: false,
      dialog1: null,
      dialog2: null,
      validationDialog: false,
      previousStartDate: null,
      editAgree: false,
      loadingOptions: false,
      showShowcase: false,
      advanced: false,
      advancedW: false,
      windowWarn: false,
      windowWarned: false,
      propEdit: false,
      convert: false,
      orgProps: null
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'getPageInfo'
    ]),
    pageInfo () {
      return this.tournamentIn && this.tournamentIn.organization && this.getPageInfo(this.tournamentIn.organization.username)
    },
    showCartFees () {
      if (this.user && this.user.vbl) return true
      if (this.tournament && this.tournament.hasCartFees) return true
      const p = this.pageInfo
      return p && p.props.includes('cartFees')
    },
    ncaaAllowed () {
      return (this.pageInfo && this.pageInfo.props.includes('ncaa')) || (this.user && this.user.vbl) || (this.division && this.division.isNcaa)
    },
    statuses () {
      return StatusSelect
    },
    sports () {
      return Sports
    },
    surfaces () {
      return Surfaces
    },
    allowTbd: {
      get () {
        return this.tournament.props.includes('allowTbd')
      },
      set (val) {
        if (val) {
          !this.tournament.props.includes('allowTbd') && this.tournament.props.push('allowTbd')
        } else {
          const i = this.tournament.props.findIndex(f => f === 'allowTbd')
          this.tournament.props.splice(i, 1)
        }
      }
    },
    isFlyer: {
      get () {
        return this.tournament.props.includes('isFlyer')
      },
      set (val) {
        if (val) {
          !this.tournament.props.includes('isFlyer') && this.tournament.props.push('isFlyer')
        } else {
          this.tournament.props = this.tournament.props.filter(f => f !== 'isFlyer')
        }
      }
    },
    avp () {
      return AVP
    },
    showcase () {
      return this.showShowcase || this.forceShowcase
    },
    forceShowcase () {
      return this.editableDivision.allFields.includes('clubDirectorName') ||
      this.editableDivision.allFields.includes('clubDirectorEmail') ||
      this.editableDivision.allFields.includes('sandRecruitsNumber') ||
      this.editableDivision.allFields.includes('committed') ||
      this.editableDivision.allFields.includes('parent') ||
      this.editableDivision.allFields.includes('emergency')
    },
    locked () {
      return (this.divisionIn && this.divisionIn.complete) || (this.tournamentIn && this.tournamentIn.locked)
    },
    division () {
      return this.divisionId ? this.tournament.divisions.find(f => f.id === this.divisionId) : null
    },
    divisionIn () {
      return this.divisionId ? this.tournamentIn.divisions.find(f => f.id === this.divisionId) : null
    },
    sport: {
      get: function () {
        return this.editableDivision && (this.editableDivision.sportId || 1)
      },
      set: function (val) {
        if (this.division) {
          this.division.sportId = val
        } else {
          this.tournament.adminDivisions.forEach(d => {
            d.sportId = val
          })
        }
      }
    },
    showSurface () {
      const s = this.sports.find(f => f.id === this.sport)
      return s.showSurfaces
    },
    surface: {
      get: function () {
        return this.editableDivision && (this.editableDivision.surfaceId || 1)
      },
      set: function (val) {
        console.log(val)
        if (this.division) {
          this.division.surfaceId = val
        } else {
          this.tournament.adminDivisions.forEach(d => {
            d.surfaceId = val
          })
        }
      }
    },
    startDate: {
      get: function () {
        return this.division ? this.division.days[0].date : this.tournament.startDate
      },
      set: function (val) {
        if (this.division) {
          this.previousStartDate = this.division.days[0].date
          this.division.days[0].date = val
        } else {
          this.previousStartDate = this.tournament.startDate
          this.tournament.startDate = val
          this.tournament.adminDivisions.forEach(d => {
            d.days[0].date = val
          })
        }

        if (this.previousStartDate) {
          this.adjDialog = true
        } else {
          this.onDateChange()
        }
      }
    },
    checkIn: {
      get: function () {
        return this.editableDivision && this.editableDivision.days[0].checkInTime
      },
      set: function (val) {
        if (this.division) {
          this.division.days[0].checkInTime = val
        } else {
          this.tournament.adminDivisions.forEach(d => {
            d.days[0].checkInTime = val
          })
        }
      }
    },
    playTime: {
      get: function () {
        return this.editableDivision && this.editableDivision.days[0].playTime
      },
      set: function (val) {
        if (this.division) {
          this.division.days[0].playTime = val
        } else {
          this.tournament.adminDivisions.forEach(d => {
            d.days[0].playTime = val
          })
        }
      }
    },
    location: {
      get: function () {
        return this.editableDivision && this.editableDivision.location
      },
      set: function (val) {
        if (this.division) {
          this.division.location = val
        } else {
          this.tournament.adminDivisions.forEach(d => {
            d.location = val
          })
        }
      }
    },
    registrationWindows () {
      return this.division ? this.division.registrationWindows : this.editableDivision.registrationWindows
    },
    dtRefundCutoff: {
      get: function () {
        return this.editableDivision && this.editableDivision.dtRefundCutoff
      },
      set: function (val) {
        if (this.division) {
          this.division.dtRefundCutoff = val
        } else {
          this.tournament.adminDivisions.forEach(d => {
            d.dtRefundCutoff = val
          })
        }
      }
    },
    refundPolicy: {
      get: function () {
        return this.editableDivision && this.editableDivision.refundPolicyId
      },
      set: function (val) {
        if (this.division) {
          this.division.refundPolicyId = val
        } else {
          this.tournament.adminDivisions.forEach(d => {
            d.refundPolicyId = val
          })
        }
      }
    },
    emailNote: {
      get: function () {
        return this.division ? this.division.emailNote || this.tournament.emailNote : this.tournament.emailNote
      },
      set: function (val) {
        if (this.division) {
          this.division.emailNote = val
        } else {
          this.tournament.emailNote = val
          this.tournament.adminDivisions.forEach(d => {
            d.emailNote = null
          })
        }
      }
    },
    myProps: {
      get: function () {
        return this.division ? this.division.props.join(',') : this.tournament.props.join(',')
      },
      set: function (val) {
        const v = val ? val.split(',') : []
        if (this.division) {
          this.division.props.splice(0, this.division.props.length, ...v)
        } else {
          this.tournament.props.splice(0, this.tournament.props.length, ...v)
        }
      }
    },
    showPropPush () {
      if (!this.division || this.viewOnly) return false
      const diff = this.tournament.adminDivisions.find(f => f.props.join(',') !== this.myProps)
      return !!diff
    },
    editableDivision () {
      return this.division || (this.tournament && this.tournament.adminDivisions[0])
    },
    lastDivision () {
      return this.tournament && this.tournament.adminDivisions[this.tournament.adminDivisions.length - 1]
    },
    activeFab () {
      return this.viewOnly ? {
        color: 'color3Text color3--text',
        icon: 'fas fa-pencil-alt',
        onClick: this.editMode,
        tooltip: 'Edit'
      } : {
        color: 'red white--text',
        icon: 'fas fa-times',
        onClick: this.cancel,
        tooltip: 'Cancel'
      }
    },
    locations () {
      const base = this.options.locationOptions
      if (this.location && !base.find(b => b.id === this.location.id)) {
        base.push(this.location)
      }
      return base
    },
    organizations () {
      const base = JSON.parse(JSON.stringify(this.user.pages))
      const t = this.tournament
      if (t && t.organization && !base.find(b => b.id === t.organization.id)) {
        base.push(t.organization)
      }
      return base
    },
    dirty () {
      return this.tournament && (this.tournament.id === 0 || this.oDto !== JSON.stringify(this.tournament.dto))
    },
    dto () {
      return this.tournament && this.tournament.dto
    },
    fieldChoices () {
      if (this.editableDivision) {
        this.myfieldChoices.forEach(f => {
          const l = this.editableDivision.getFieldList(f.value)
          if (f.list !== l) f.list = l
          const s = !!this.divisionId || this.tournament.simpleFieldChoice(f.value, f.list)
          if (f.simple !== s) f.simple = s
        })
      }
      return this.myfieldChoices
    },
    additionalFields () {
      if (this.editableDivision) {
        this.myAdditionalFields.forEach(f => {
          const l = this.editableDivision.getFieldList(f.value)
          if (f.list !== l) f.list = l
          const s = !!this.divisionId || this.tournament.simpleFieldChoice(f.value, f.list)
          if (f.simple !== s) f.simple = s
        })
      }
      return this.myAdditionalFields
    },
    isGnbv () {
      // return this.tournament.organization.username === 'gnbv' || this.user.vbl
      return (this.tournament.organization && ['gnbv', 'flourish'].includes(this.tournament.organization.username)) || this.user.vbl
    },
    bvneFields () {
      if (this.editableDivision) {
        this.myBvneFields.forEach(f => {
          const l = this.editableDivision.getFieldList(f.value)
          if (f.list !== l) f.list = l
          const s = !!this.divisionId || this.tournament.simpleFieldChoice(f.value, f.list)
          if (f.simple !== s) f.simple = s
        })
      }
      return this.myBvneFields
    },
    flags () {
      if (this.editableDivision) {
        this.myFlags.forEach(f => {
          const l = this.editableDivision.getFieldList(f.value)
          if (f.list !== l) f.list = l
          const s = !!this.divisionId || this.tournament.simpleFieldChoice(f.value, f.list)
          if (f.simple !== s) f.simple = s
          if (f.deps) {
            const x = !!this.editableDivision.getFieldList(f.deps)
            if (f.show !== x) f.show = x
          }
        })
      }
      return this.myFlags.filter(f => f.show)
    },
    genders () {
      if (!this.options) return null
      if (!this.division || !this.division.ageType) return this.options.genderOptions

      return this.options.genderOptions.filter((opt) => { return opt.ageTypeId === this.division.ageType.id })
    },
    divisions () {
      if (!this.division || !this.division.ageType) return this.options.divisionOptions

      return this.options.divisionOptions.filter((opt) => { return opt.ageTypeId === this.division.ageType.id })
    },
    errors () {
      const e = []
      !this.tournament.name && e.push('enter a name')
      !this.startDate && e.push('choose a start date')
      !this.location && !this.user.vbl && e.push('select a location')
      return e
    },
    // SIMPLES
    warnMe () {
      return !(
        this.simpleSport &&
        this.simpleSurface &&
        this.simpleStart &&
        this.simpleCheckIn &&
        this.simplePlay &&
        this.simpleLocation &&
        this.registrationSimplicity.all &&
        this.simpleRefund.date &&
        this.simpleRefund.time &&
        this.simpleRegistrationFields
      )
    },
    simpleSport () {
      return !!this.divisionId || (!this.tournament || this.tournament.simpleSport)
    },
    simpleSurface () {
      return !!this.divisionId || (!this.tournament || this.tournament.simpleSurface)
    },
    simpleStart () {
      return !!this.divisionId || (!this.tournament || this.tournament.simpleStart)
    },
    simpleCheckIn () {
      return !!this.divisionId || (!this.tournament || this.tournament.simpleCheckIn)
    },
    simplePlay () {
      return !!this.divisionId || (!this.tournament || this.tournament.simplePlay)
    },
    simpleLocation () {
      return !!this.divisionId || (!this.tournament || this.tournament.simpleLocation)
    },
    registrationSimplicity () {
      return !this.tournament || this.tournament.registrationSimplicity(this.divisionId)
    },
    simpleRefund () {
      return this.divisionId ? { date: true, time: true } : (!this.tournament || this.tournament.simpleRefund)
    },
    simpleRefundPolicy () {
      return !!this.divisionId || (!this.tournament || this.tournament.simpleRefundPolicy)
    },
    simpleRegistrationFields () {
      return !!this.divisionId || (!this.tournament || this.tournament.simpleRegistrationFields)
    },
    simpleEmail () {
      return !!this.divisionId || (!this.tournament || this.tournament.simpleEmail)
    },
    disabled () {
      return this.saving || this.viewOnly
    },
    optionsLoaded () {
      return this.options.ageTypeOptions.length
    },
    forceAdvanced () {
      return (this.divisionId && this.division && (!!this.division._Name || this.division.numOfPlayers !== 2 || this.division.numAllowedOnRoster !== 2 || this.division.useTeamNames)) || this.sport !== 1 || this.surface !== 1 || !this.simpleSport || !this.simpleSurface
    }
  },
  methods: {
    pushProps () {
      if (!this.division) return
      this.tournament.adminDivisions.forEach(f => { f.props = this.division.props })
    },
    addWindow () {
      if (!this.windowWarned) {
        this.windowWarn = true
      } else {
        this.addWindow2()
      }
    },
    addWindow2 () {
      if (this.division) {
        this.division.addWindow()
      } else {
        this.tournament.addWindow()
      }
    },
    deleteWindow (i) {
      if (this.division) {
        this.division.deleteWindow(i)
      } else {
        this.tournament.deleteWindow(i)
      }
    },
    onSaveClick () {
      if (this.errors.length) {
        this.validationDialog = true
        return
      }
      this.saveTournament()
    },
    onWarningClick (field) {
      this.dialog1 = `Not all divisions ${typeof field === 'string' ? `share the same ${field.toLowerCase()}` : field.description.toLowerCase()}`
      this.dialog2 = typeof field === 'string' ? field.toLowerCase() : `${field.value.toLowerCase()} requirements`
      this.warningDialog = true
    },
    onAddLocationClick (e) {
      this.$refs.newLocation.dialog = true
      this.$refs.locationSelect.blur()
    },
    onLocationCreated (newLocation) {
      this.options.locationOptions.push(newLocation)
      this.options.locationOptions.sort(firstBy('name'))
      this.location = newLocation
    },
    editMode () {
      this.loadTournament()
      if (!this.divisionId && this.warnMe) {
        this.warnMeDialog = true
      }
      this.viewOnly = false
    },
    loadTournament () {
      this.clearTournament()
      this.oDto = this.tournamentIn ? JSON.stringify(this.tournamentIn.dto) : null
      const t = this.tournamentIn && this.tournamentIn.id === 0 ? this.tournamentIn : new Tournament(this.$VBL, this.oDto)
      if (!t.organization && this.organizations.length === 1) {
        t.organization = this.organizations[0]
      } else if (!t.organization && this.$route.params.username) {
        const i = this.organizations.find(o => {
          return o.username === this.$route.params.username
        })
        if (i) t.organization = i
      }

      if (!t.divisions.length) {
        t.divisions.push(
          new Division(this.$VBL, {
            _Name: this.eventType && !['Tournament', 'League', 'Training Session'].includes(this.eventType) ? 'Girls Ages: 10 - 18' : null,
            days: [new Day(this.sdk)],
            registrationWindows: [{ fee: 80 }]
          })
        )
      }

      this.tournament = t

      if (this.eventType && this.eventType !== t.eventType) {
        this.tournament.props.push(`is${this.eventType.replace(' ', '')}`)
      }
      if (this.nameIn) t.name = this.nameIn
      if (this.startDateIn) {
        this.previousStartDate = this.tournament.startDate
        this.tournament.startDate = this.startDateIn
        this.tournament.divisions.forEach(d => {
          d.days[0].date = this.startDateIn
          d.props.push(`is${t.eventType}`)
          if (!d.isTournament && !d.isLeague) {
            d.numOfPlayers = 1
            d.numAllowedOnRoster = 1
          }
        })
        this.onDateChange()
        if (t.isLeague && !t.leagueSettings) {
          if (!this.tournament.jProps) this.tournament.jProps = {}
          this.tournament.jProps.leagueSettings = (new LeagueSettings()).dto
        }
      }
      this.oneDay = t._startDate === t._endDate

      if (!this.optionsLoaded) {
        this.loadOptions()
      } else {
        this.setDefaultOptions()
      }
    },
    clearTournament () {
      this.inited = false
      this.tournament = null
      this.oDto = ''
      this.myfieldChoices.forEach(f => {
        f.list = false
      })
      this.customFields = []
      this.customRequiredFields = []
    },
    saveTournament () {
      this.saving = true
      if (this.divisionId) {
        this.division.props = this.division.props.filter(f => !f.startsWith('lineups-dual-'))
        if (this.division.lineups && this.division.numOfPlayers > 2) {
          this.division.props.push(`lineups-dual-${this.division.numOfPlayers / 2}`)
        }
      }
      this.$VBL.tournament.save(this.tournament)
        .then((response) => {
          if (!this.divisionId && (this.$route.name !== 'tournament-admin' || +this.$route.params.tournamentId !== response.data)) {
            this.$router.push({
              name: 'tournament-admin',
              params: {
                tournamentId: response.data
              }
            })
          } else {
            this.viewOnly = true
          }
        })
        .catch((error) => {
          console.log(error.response)
        })
        .finally(() => {
          this.saving = false
        })
    },
    updateRegistrationWindow (dto, i) {
      const update = (division) => {
        const w = division.registrationWindows[i]
        if (w) w[dto.field] = dto.value
      }
      if (this.division) {
        update(this.division)
      } else {
        this.tournament.adminDivisions.forEach(d => {
          update(d)
        })
      }
    },
    onFieldChange (list, field) {
      const update = (division) => {
        const f = division.registrationFields.fields
        const fi = f.indexOf(field)
        const rf = division.registrationFields.requiredFields
        const rfi = rf.indexOf(field)

        switch (list) {
          case 'fields':
            fi === -1 && f.push(field)
            rfi > -1 && rf.splice(rfi, 1)
            break
          case 'requiredFields':
            rfi === -1 && rf.push(field)
            fi > -1 && f.splice(fi, 1)
            break
          default:
            fi > -1 && f.splice(fi, 1)
            rfi > -1 && rf.splice(rfi, 1)
            break
        }
      }

      if (this.division) {
        update(this.division)
      } else {
        this.tournament.adminDivisions.forEach(d => update(d))
      }

      if (list) {
        const a = this.myFlags.filter(f => f.deps === 'dob').map(m => m.value)
        const b = a.includes(field) ? a.filter(f => f !== field) : []
        b.forEach(f => this.onFieldChange(null, f))
      }
    },
    cancel () {
      this.$emit('cancel-click')
      if (this.dirty) {
        this.loadTournament()
      }
      this.viewOnly = true
    },
    onDateChange () {
      console.log('onDateChange')
      const n = this.startDate
      const o = this.previousStartDate
      const adj = o ? moment(n).diff(moment(o)) : 0

      // endDate
      if (!this.division) {
        if (this.oneDay) {
          this.tournament.endDate = n.format('YYYY-MM-DD')
        } else {
          this.tournament.endDate = this.tournament.endDate.add(adj)
        }
      }
      const update = (division) => {
        // Reg window
        division.registrationWindows.forEach((window, i) => {
          window.dtEnd = window.dtEnd ? window.dtEnd.add(adj) : n.clone().add(-1, 'days').format('YYYY-MM-DD[T17:00]')
          if (i > 0 && window.dtStart) {
            window.dtStart = window.dtStart.add(adj)
          }
        })
        // Refund
        division.dtRefundCutoff = division.dtRefundCutoff ? division.dtRefundCutoff.add(adj) : n.clone().add(-3, 'days').format('YYYY-MM-DD[T17:00]')
      }
      if (this.division) {
        update(this.division)
      } else {
        this.tournament.divisions.forEach(d => {
          update(d)
        })
      }
    },
    onEditorReady (quill) {
      quill.options.modules = { toolbar: false }
    },
    loadOptions () {
      this.loadingOptions = true
      this.$VBL.get.selectOptions()
        .then(r => {
          this.options = r.data
          this.setDefaultOptions()
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loadingOptions = false
        })
    },
    loadOptions2 () {
      this.loadingOptions = true
      this.$http.get(`${window.location.origin}/static/json/states.json`)
        .then(r => {
          this.options = r.data
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loadingOptions = false
        })
    },
    onPreviewClick (image) {
      this.selectedImage = image
      this.tournament.coverPhotoId = image.id
      this.tournament.coverPhotoUrl = image.url
    },
    toggleOneday () {
      if (this.disabled) return
      this.oneDay = !this.oneDay
      if (this.oneDay) {
        this.tournament.endDate = this.tournament.startDate
      } else {
        this.tournament.endDate = this.tournament.startDate.add(1, 'days').format('YYYY-MM-DD')
      }
    },
    checkForPoints (username) {
      this.pointOptions = 'loading'
      this.$VBL.organization.pointSystems.getOptions(username)
        .then(r => {
          this.pointOptions = r.data
          if (this.tournament.id === 0) {
            const defaults = r.data.filter(f => f.isDefault)
            const add = defaults.filter(f => !this.tournament.pointSystems.find(x => x.id === f.id))
            this.tournament.pointSystems.push(...add)
          }
        })
        .catch(e => {
          console.log(e.response)
          this.pointOptions = null
        })
    },
    setDefaultOptions () {
      const t = this.tournament
      if (!t) return

      t.divisions.forEach(d => {
        if (!d.ageType) d.ageType = this.options.ageTypeOptions.find(f => f.id === 1)
        if (!d.gender) d.gender = this.genders.find(f => f.id === 4)
        if (!d.division) d.division = this.divisions.find(f => f.id === 6)
      })
    },
    playerBlur () {
      const val = this.division.numOfPlayers
      if (val) {
        if (this.division.numAllowedOnRoster < val) this.division.numAllowedOnRoster = val
      } else {
        this.division.numOfPlayers = this.division.numAllowedOnRoster = 2
      }
    },
    loadOrgProps () {
      this.tournament.getOrgProps()
        .then(r => { this.orgProps = r.data })
    }
  },
  watch: {
    'tournament.organization': function (n, o) {
      if (n && this.tournament.isTournament) {
        this.checkForPoints(n.username)
      }
      if (n && o) {
        this.tournament.coverPhotoUrl = this.tournamentIn ? this.tournamentIn.coverPhotoUrl : null
      }
      this.loadOrgProps()
    },
    tournamentIn: function (val) {
      this.loadTournament()
    }
  },
  components: {
    RegistrationWindow,
    DivisionListSimple,
    DateTimePicker,
    HtmlEditor,
    LocationWizard,
    ImageLibrary,
    ExternalRegistration,
    SanctioningBodies,
    LeagueSettings,
    RefundPolicySelector,
    CartFees,
    Membership,
    AddOnDrawer,
    ReoccuringSettings
  },
  mounted () {
    this.loadTournament()
    this.$nextTick(() => {
      if (this.forceAdvanced) this.advanced = true
    })
    this.loadOrgProps()
  }
}
</script>

<style>
  .styledArea textarea {
    border-bottom: 2px solid #ccc;
  }
  .styledArea textarea:focus {
    border-bottom: 2px solid rgb(21, 42, 105);
  }
  .ql-editor p {
    margin-bottom: 16px;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .quill-editor {
    z-index: 0;
  }
</style>
